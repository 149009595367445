














import {Component, Vue} from 'vue-property-decorator';

Component({
    name: "DashboardLayoutMenu"
})
export default class DashboardLayoutMenu extends Vue {
}
